var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'DashboardHome',
            }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'AutoawardList',
            }}},[_vm._v("自動派獎設定")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("自動派獎列表")])],1)],1)])]),_c('b-card',[_c('div',{staticClass:"row d-flex mb-4 mb-xl-2 justify-content-between"},[_c('h4',{staticClass:"col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold"},[_vm._v("自動派獎列表")]),_c('div',{staticClass:"\n          col-12 col-xl-6\n          d-flex\n          flex-column flex-xl-row\n          align-items-start align-items-xl-center\n        "},[_c('b-form-select',{attrs:{"options":_vm.merchants},on:{"change":_vm.fetchAutoawards},model:{value:(_vm.merchantId),callback:function ($$v) {_vm.merchantId=$$v},expression:"merchantId"}}),(
            _vm.checkPermission([_vm.consts.POYA_AUTOAWARD_CREATE]) && !!this.merchantId
          )?_c('b-button',{staticClass:"flex-shrink-0 mb-2 mb-xl-0 mr-2",attrs:{"variant":"primary"},on:{"click":_vm.handleCreateV3}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v("新增派獎 ")]):_vm._e()],1)]),_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","items":_vm.autoawards,"fields":_vm.fields,"busy":_vm.isLoading,"no-local-sorting":"","sort-by":_vm.orderBy,"sort-desc":_vm.orderDesc},on:{"sort-changed":_vm.sortingChanged,"update:sortBy":function($event){_vm.orderBy=$event},"update:sort-by":function($event){_vm.orderBy=$event},"update:sortDesc":function($event){_vm.orderDesc=$event},"update:sort-desc":function($event){_vm.orderDesc=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"cell(actions)",fn:function(data){return [(_vm.checkPermission([_vm.consts.POYA_AUTOAWARD_VIEW]))?_c('b-button',{attrs:{"variant":"inverse-primary","disabled":data.item.config.version != 3,"to":{
            name: 'AutoawardViewV3',
            params: {
              autoawardId: data.item.id,
            },
          }}},[_vm._v("查看")]):_vm._e(),(_vm.checkPermission([_vm.consts.POYA_AUTOAWARD_MODIFY]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-warning","to":{
            name: 'AutoawardEditV3',
            params: {
              autoawardId: data.item.id,
            },
          },"disabled":_vm.isAfter(new Date(), new Date(data.item.end_at)) || data.item.config.version != 3}},[_vm._v("編輯")]):_vm._e(),(_vm.checkPermission([_vm.consts.POYA_AUTOAWARD_MODIFY]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"danger","disabled":_vm.isAfter(new Date(), new Date(data.item.start_at))},on:{"click":function($event){return _vm.handleDelete(data.item.id, data.item.title)}}},[_vm._v("刪除")]):_vm._e()]}}])}),_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"margin-top":"80px"}},[_c('b-pagination',{staticClass:"separated",attrs:{"total-rows":_vm.total,"per-page":_vm.perPage,"aria-controls":"autoawardList"},on:{"change":function (page) { return _vm.fetchAutoawards(page); }},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }