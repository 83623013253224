import https from "./https";
import store from "@/store";

const autoaward = {
  getAutoawards(merchantId, params) {
    const organization = store.state.general.organization;
    const searchParams = new URLSearchParams(params);

    return https.get(
      `/admin/organizations/${organization.id}/merchants/${merchantId}/autoawards?${searchParams}`
    );
  },

  getAutoaward(autoawardId) {
    return https.get(`/admin/autoawards/${autoawardId}`);
  },

  createAutoaward(merchantId, autoaward) {
    const organization = store.state.general.organization;

    return https.post(
      `/admin/organizations/${organization.id}/merchants/${merchantId}/autoawards`,
      autoaward 
    );
  },

  updateAutoaward(autoawardId, autoaward) {
    return https.put(`/admin/autoawards/${autoawardId}`, autoaward);
  },

  deleteAutoaward(autoawardId) {
    return https.delete(`/admin/autoawards/${autoawardId}`);
  },
};

export default autoaward;
